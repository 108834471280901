<template>
  <div class="container">
    <div class="usable-card">
      <div class="title">
        <div class="left">可领取</div>
      </div>
      <div class="card-box">
        <div class="card" v-for="(item,i) in 5" :key="i">
          <div class="card-left">
            <div class="name">菲灵魔法定型发胶</div>
            <div class="size">500ml</div>
            <div class="num">剩余寄存数量：<b>20</b></div>
          </div>
          <div class="card-right">
            <div class="btn" @click="getGoodsShow=true">领取商品</div>
            <div class="history" @click="cardDetailShow=true">
              领取记录<span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="failure-card usable-card">
      <div class="title">
        <div class="left">已领完</div>
      </div>
      <div class="card-box">
        <div class="card" v-for="(item,i) in 5" :key="i">
          <div class="card-left">
            <div class="name">菲灵魔法定型发胶</div>
            <div class="size">500ml</div>
            <div class="num">已领完</div>
          </div>
          <div class="card-right">
            <div class="history" @click="cardDetailShow=true">
              领取记录<span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 领取商品 -->
    <el-dialog class="get-goods-dialog" :visible.sync="getGoodsShow" width="40%" :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          领取商品
        </div>
      </template>
      <div class="card cursor">
        <div class="card-top">
          <div class="name">菲灵魔法定型发胶</div>
          <div class="size">500ml</div>
        </div>
        <div class="card-btm">
          <div class="b-left">剩余寄存</div>
          <div class="b-right"><b>10</b></div>
        </div>
        <div class="card-btm">
          <div>本次领取</div>
          <div class="stepper">
            <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="99">
            </el-input-number>
          </div>
        </div>
        <div class="btn">
          <el-button type="primary">
            确认领取
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 领取记录 -->
    <el-dialog :visible.sync="cardDetailShow" width="40%" :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          领取记录
        </div>
      </template>
      <el-container style="height:510px;background-color:#fff;">
        <el-header class="dia-header">
          <div class="card cursor">
            <div class="card-top">
              <div class="name">菲灵魔法定型发胶</div>
              <div class="size">500ml</div>
            </div>
            <div class="card-btm">
              <div class="b-left">剩余寄存：<b>10</b></div>
              <div class="b-right">已领取：<b>10</b></div>
            </div>
          </div>
        </el-header>
        <el-main class="dia-main">
          <div class="dia-body">
            <div class="b-title el-icon-document">
              消费记录
            </div>
            <div class="table">
              <div class="thead">
                <div class="th">领取时间</div>
                <div class="th">领取数量</div>
              </div>
              <div class="tbody" v-for="(item,i) in 20" :key="i">
                <div class="td">2021/02/01 12:00</div>
                <div class="td">-2</div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        getGoodsShow: false,
        cardDetailShow: false,
        stepperNum: 1,
      }
    },

    methods: {
      handleClose() {
        this.getGoodsShow = false
        this.cardDetailShow = false
      }
    },
  }

</script>

<style lang="less" scoped>
  .usable-card {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      height: 50px;
      color: #666;
      font-weight: bold;
    }

    .card-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      .card {
        width: 48%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0.5% 10px;
        box-shadow: 0 0 2px #ccc;
        padding: 10px;
        border-radius: 10px;
        box-sizing: border-box;

        .card-left {
          .name {
            margin-bottom: 15px;
          }

          .size {
            color: #999;
            font-size: 12px;
            margin-bottom: 10px;
          }

          .num {
            color: #999;

            b {
              color: #f56c6c;
            }
          }
        }

        .card-right {
          text-align: right;

          .btn {
            width: 80px;
            line-height: 30px;
            border-radius: 5px;
            background-color: #409eff;
            color: #fff;
            text-align: center;
            margin-bottom: 10px;
          }

          .history {
            color: #999;
          }
        }
      }
    }


  }

  .failure-card {
    color: #666;
  }

  .get-goods-dialog {
    /deep/ .el-dialog__body {
      height: auto !important;
      padding: 10px;
    }

    .card {
      .card-top {
        padding: 10px;
        border-bottom: 1px solid #f2f2f2;

        .name {
          font-weight: bold;
          margin-bottom: 10px;
        }

        .size {
          font-size: 12px;
          color: #999;
        }
      }

      .card-btm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;

        b {
          color: #f56c6c;
        }

        .stepper {

          /deep/ .el-input-number {
            width: 100px;
            height: 24px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .el-input-number__decrease,
            .el-input-number__increase {
              width: 24px;
              height: 24px;
              box-sizing: border-box;
              text-align: center;
              line-height: 20px;
              border-radius: 100%;
              border: 2px solid #ccc;
              color: #666;
              font-weight: bold;
              background-color: #fff;
            }

            .el-input {
              width: 100px;
              height: 34px;

              .el-input__inner {
                height: 24px;
                line-height: 24px;
                padding: 0 15px;
                background-color: #fff;
                color: #666;
                font-weight: bold;
                border: none;
              }
            }
          }
        }
      }

      .btn {
        padding: 20px 30px;

        .el-button {
          width: 100%;
        }
      }
    }
  }

  /deep/ .el-dialog {
    border-radius: 10px;
    min-width: 400px;

    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        top: 14px;
      }

    }

    .dia-title {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #f2f2f2;
      padding: 10px 0;
      text-align: center;
    }

    .el-dialog__body {
      height: 500px;
      padding: 10px;
    }

    .el-header {
      height: 120px !important;
      padding: 0 20px;

      .card {
        margin: 10px 0;

        .card-top {
          padding: 10px;
          border-bottom: 1px solid #f2f2f2;

          .name {
            font-weight: bold;
            margin-bottom: 10px;
          }

          .size {
            font-size: 12px;
            color: #999;
          }
        }

        .card-btm {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 0;

          b {
            color: #f56c6c;
          }

        }
      }
    }

    .el-main {
      padding: 0 !important;
    }

    .dia-main {
      padding: 0 20px !important;
    }

    .dia-body {
      .b-title {
        font-weight: bold;
        margin: 10px 0 15px;

        &::before {
          font-weight: normal;
        }
      }

      .table {

        .thead,
        .tbody {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .th,
          .td {
            width: 50%;
            text-align: center;
            line-height: 40px;
            font-size: 12px;

          }

          .td {
            &:nth-child(4) {
              color: #409eff;
              border: 1px solid #409eff;
              line-height: 22px;
              border-radius: 36px;
            }
          }
        }

        .thead {
          background-color: #f5f5f5;
        }

        .tbody {
          border-bottom: 1px solid #f2f2f2;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

  }

</style>
